import { Box, List, ListItem } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import * as React from 'react';
import { SEO } from '@/components/seo';

type Props = {
  pageTitle: string;
};

export const BaseLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  pageTitle,
}) => (
  <Box>
    <SEO />
    <title>{pageTitle}</title>
    <nav>
      <List>
        <ListItem>
          <Link to="/">Home</Link>
        </ListItem>
        <ListItem>
          <Link to="/about">About</Link>
        </ListItem>
      </List>
    </nav>
    <main>{children}</main>
  </Box>
);
