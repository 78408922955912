import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultKeywords: keywords
        defaultImage: image
        defaultUrl: url
        defaultOgTitle: ogTitle
        defaultOgImage: ogImage
        defaultOgDescription: ogDescription
      }
    }
  }
`;

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  url?: string;
  image?: string;
  ogTitle?: string;
  ogImage?: string;
  ogDescription?: string;
  meta: HelmetProps['meta'];
};

export const SEO: React.FC<Props> = ({
  title,
  description,
  keywords,
  url,
  image,
  ogTitle,
  ogDescription,
  ogImage,
  meta = [],
}) => {
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    defaultKeywords,
    defaultImage,
    defaultUrl,
    defaultOgTitle,
    defaultOgImage,
    defaultOgDescription,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: image || defaultImage,
    url: url || defaultUrl,
    ogTitle: ogTitle || defaultOgTitle,
    ogDescription: ogDescription || defaultOgDescription,
    ogImage: ogImage || defaultOgImage,
  };

  return (
    <Helmet
      title={seo.title}
      meta={[
        { name: 'description', content: seo.description },
        { name: 'keywords', content: seo.keywords },
        { name: 'image', content: seo.image },
        {
          property: 'og:url',
          content: url || 'https://giftee.biz',
        },
        { property: 'og:title', content: seo.ogTitle },
        { property: 'og:description', content: seo.ogDescription },
        { property: 'og:url', content: seo.url },
        { property: 'og:image', content: seo.ogImage },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'giftee for Business' },
        ...meta,
      ]}
    />
  );
};
